import React from 'react';
import './ContactUs.css';
import Footer from '../Footer/Footer';
import Navbar1 from '../Navbar/Navbar1';
import Navbar2 from '../Navbar/Navbar2';
import contactImage from '../Assest/cacs.jpg'; // Replace with your actual image path

const ContactUs = () => {
  return (
    <>
      <Navbar1 />
      <Navbar2 />
      <div className="contact-us-section">
        {/* Full-width Contact Image and Message Form in One Row */}
        <div className="contact-content">
          <div className="contact-image-container">
            <img src={contactImage} alt="Contact" className="contact-image" />
          </div>
          <div className="message-form">
            <h3>Send us a message <span role="img" aria-label="rocket">🚀</span></h3>
            <form>
              <input type="text" name="full-name" placeholder="Full name*" required />
              <input type="email" name="email" placeholder="Email address*" required />
              <input type="text" name="subject" placeholder="Subject" />
              <textarea name="message" placeholder="Tell us more about your project*" required></textarea>
              <button type="submit" className='contactus-btn'>Submit</button>
            </form>
          </div>
        </div>

        {/* Address and Map Section in One Row */}
        <div className="address-map-content">
          <div className="address-info">
            <h3>Contact Us</h3>
            <p># B06, H-169, Sector 63, Noida, 201301, U.P., INDIA</p>
            <p>Email: <a href="mailto:info@acstechconsulting.com">info@acstechconsulting.com</a></p>
            {/* Phone Numbers Section */}
            <div className="country-numbers">
              <h3>Call Us</h3>
              <ul className='call-number'>
                <li>
                  India: <a href="tel:+919315652636">+91 9315652636</a> |
                  <a href="https://wa.me/919315652636" target="_blank" rel="noopener noreferrer"> WhatsApp</a>
                </li>
                <li>
                  USA: <a href="tel:+12024465842">+1 (945) 301-9521</a> |
                  <a href="https://wa.me/19453019521" target="_blank" rel="noopener noreferrer"> WhatsApp</a>
                </li>
                <li>
                  Denmark: <a href="tel:+4591664592">+45 91 66 45 92</a> |
                  <a href="https://wa.me/4591664592" target="_blank" rel="noopener noreferrer"> WhatsApp</a>
                </li>
              </ul>
            </div>
          </div>
          {/* Map Section */}
          <div className="map-container">
            <h3>Our Location</h3>
            <iframe
              title="Google Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13701.748012430363!2d77.35908080501615!3d28.58024301452485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390c4e4e4e4e4e4f%3A0xe4e4e4e4e4e4e4e4!2sB06%2C%20H-169%2C%20Sector%2063%2C%20Noida%2C%20U.P.%20201301!5e0!3m2!1sen!2sin!4v1600000000000!5m2!1sen!2sin"
              width="600"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ContactUs;
