import React from 'react';
import "./Carrer.css"; // Import the CSS file
import Footer from '../Footer/Footer';
import Navbar1 from '../Navbar/Navbar1';
import Navbar2 from '../Navbar/Navbar2';

const JobApplicationForm = () => {
  return (
    <>
    <Navbar1/>
    <Navbar2/>
    <div className="full-page">
      <form>
        <h1>Discover Amazing Opportunities Today</h1>

        <div className="form-row">
          <div className="form-column">
            <label htmlFor="name">Name:</label>
            <input type="text" id="name" placeholder="Full Name" />

            <label htmlFor="mobile">Mobile No:</label>
            <input type="text" id="mobile" placeholder="Mobile No" />
          </div>

          <div className="form-column">
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" placeholder="Email address" />

            <label htmlFor="job-title">Job Title:</label>
            <input type="text" id="job-title" placeholder="Job Title" />
          </div>
        </div>

        <div className="skills">
          <div className="skills-column">
            <label>Please select all the skills you have:</label>

            <div className="checkbox-group">
              <input type="checkbox" id="php" />
              <label htmlFor="php">PHP Developer (CodeIgniter/Laravel)</label>
            </div>

            <div className="checkbox-group">
              <input type="checkbox" id="nodejs" />
              <label htmlFor="nodejs">Node JS Developer</label>
            </div>

            <div className="checkbox-group">
              <input type="checkbox" id="python" />
              <label htmlFor="python">Python Developer</label>
            </div>

            <div className="checkbox-group">
              <input type="checkbox" id="react" />
              <label htmlFor="react">React Developer</label>
            </div>

            <div className="checkbox-group">
              <input type="checkbox" id="angular" />
              <label htmlFor="angular">Angular Developer</label>
            </div>

            <div className="checkbox-group">
              <input type="checkbox" id="html-css" />
              <label htmlFor="html-css">HTML/CSS Developer</label>
            </div>
          </div>

          <div className="skills-column">
            <div className="checkbox-group">
              <input type="checkbox" id="java" />
              <label htmlFor="java">Java Developer</label>
            </div>

            <div className="checkbox-group">
              <input type="checkbox" id="csharp" />
              <label htmlFor="csharp">C# Developer</label>
            </div>

            <div className="checkbox-group">
              <input type="checkbox" id="dotnet" />
              <label htmlFor="dotnet">.NET Developer</label>
            </div>

            <div className="checkbox-group">
              <input type="checkbox" id="android" />
              <label htmlFor="android">Android Developer</label>
            </div>

            <div className="checkbox-group">
              <input type="checkbox" id="ios" />
              <label htmlFor="ios">iOS Developer</label>
            </div>

            <div className="checkbox-group">
              <input type="checkbox" id="data-science" />
              <label htmlFor="data-science">Data Scientist</label>
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="form-column">
            <label htmlFor="experience">Job Experience:</label>
            <input type="text" id="experience" placeholder="Job Experience" />
          </div>

          <div className="form-column">
            <label htmlFor="current-ctc">Current CTC:</label>
            <input type="text" id="current-ctc" placeholder="Current CTC" />
          </div>
        </div>

        <div className="form-row">
          <div className="form-column">
            <label htmlFor="expected-ctc">Expected CTC:</label>
            <input type="text" id="expected-ctc" placeholder="Expected CTC" />
          </div>

          <div className="form-column">
            <label htmlFor="cv">Upload Your CV:</label>
            <input type="file" id="cv" />
          </div>
        </div>

        <input type="submit" value="Submit" />
      </form>
    </div>
    <Footer/>
    </>
  );
};

export default JobApplicationForm;