import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar2.css';
import logo from "../Assest/1tt.jpeg";

const Navbar2 = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

      return (<>
    <nav class="navbar bg-white  navbar-expand-lg ">
  <div class="container-fluid bg-white ">
  <Link to="/" className="logo">
          <img src={logo} alt="Logo" />
        </Link>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav ms-auto d-flex align-items-center" >
    <Link className='menu2' to="/" onClick={closeMenu}>Home</Link>
          <div className="dropdown">
            <Link to="" className="dropbtn menu2" onClick={closeMenu}>Services</Link>
           <div className='mega-menu-container'>
            <div className={`mega-menu ${menuOpen ? 'active' : ''}`}>

              <div className="mega-menu-column">
                <h4>Mobile Development</h4>
                <ul>
                  <li><Link to="/android-development">Android Development</Link></li>
                  <li><Link to="/iphone-development">iPhone Development</Link></li>
                  <li><Link to="/cross-platfrom">Cross Platform </Link></li>
                  <li><Link to="/react-native">React Native Development</Link></li>
                  <li><Link to="/fluter-development">Flutter Development</Link></li>
                </ul>
              </div>
              <div className="mega-menu-column">
                <h4>Web Development</h4>
                <ul>
                  <li><Link to="/php-development">PHP Development</Link></li>
                  <li><Link to="/react-development">ReactJS Development</Link></li>
                  <li><Link to="/node-development">NodeJS Development</Link></li>
                  <li><Link to="/wordpress">WordPress Development</Link></li>
                </ul>
              </div>
              <div className="mega-menu-column">
                <h4>Digital Transformation</h4>
                <ul>
                  <li><Link to="/salesforce">Salesforce </Link></li>
                  <li><Link to="/erp-framwork">Inhouse ERP Framewok</Link></li>
                  <li><Link to="/odoo-digital">Odoo</Link></li>
                  <li><Link to="/ci/cd-framwork">CI/CD Framework</Link></li>
                </ul>
              </div>
              <div className="mega-menu-column">
                <h4>Digital Marketing Services</h4>
                <ul>
                  <li><Link to="/digital-marketing-services">SEO</Link></li>
                  <li><Link to="/social-media-optimization">SMO</Link></li>
                  <li><Link to="/social-media-marketing">SMM</Link></li>
                  <li><Link to="/email-marketing">Email Marketing</Link></li>
                  <li><Link to="/content-writing">Content Writing</Link></li>
                  <li><Link to="/ppc-services">PPC Services</Link></li>
                  <li><Link to="/brand-management">Brand Management</Link></li>
                </ul>
              </div>
             
            </div>
            </div>
          </div>
          <Link className='menu2' to="/about-us" onClick={closeMenu}>About Us</Link>
          <Link className='menu2' to="/outsourcing" onClick={closeMenu}>Outsourcing</Link>
          <Link className='menu2' to="/our-product" onClick={closeMenu}>Our Product</Link>
          {/* <Link className='menu2' to="/industries" onClick={closeMenu}>Industries</Link> */}
          <Link className='menu2' to="/contact-us" onClick={closeMenu}>Contact Us</Link>
          <Link className='menu2' to="/career" onClick={closeMenu}>Career</Link>
          <Link className='menu2' to="/blog" onClick={closeMenu}>Blog</Link>
          <Link to="/contact-us" className="quote-btnnav" onClick={closeMenu}>Get A Quote</Link>
      

      </ul>
    </div>
  </div>
</nav>
</>




  );
};

export default Navbar2;
