import './App.css';
import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Home from './Component/Home';
import JobApplicationForm from './Component/Carrer/Carrer';
import ContactUs from './Component/ContactUs/ContactUs';
import Services from './Component/Services/Services';
import BannerOutsource from './Component/OutSourcing/BannerOutsorce';
import Industries from './Component/Industries/Industries';
import PrivacyPolicy from './Component/PrivacyPolicy/PrivacyPolicy';
import TermsConditions from './Component/Term&Condition/Term&Condition';
import OurProduct from './Component/OurProduct/OurProduct';
import GlobalLearning from "./Component/GlobalLearning/GlobalLearning";
import AndroidDevelop from './pages/AndroidDeveloper/AndroidDevelop';
import IphoneDevelop from './pages/IphoneDevelopment/IphoneDevelop';
import CrossPlatfrom from './pages/CrossPlatform/CrossPlatfrom';
import ReactDevelopment from './pages/ReactDevelopment/ReactDevelopment';
import AOS from 'aos';
import "aos/dist/aos.css"
import FlutterDevelopment from './pages/FlutterDevelopment/FlutterDevelopment';
import PhpDevelopment from './pages/PhpDevelopment/PhpDevelopment';
import Nodedevelopment from './pages/NodeDevelopment/Nodedevelopment';
import WordpressDevelop from './pages/WordpressDevelopment/WordpressDevelop';
import Salesforce from './pages/Salesforce/Salesforce';
import Erpframwork from './pages/Erpframwork/Erpframwork';
import CdFramwork from './pages/CdFramwork/CdFramwork';
import EmailMarketing from './pages/EmailMarketing/EmailMarketing';
import PpcManagement from './pages/PPCManagement/PpcManagement';
import BrandManagement from './pages/BrandManagement/BrandManagement';
import DigitalSeo from './pages/DigitalSEO/DigitalSeo';
import SmoDigital from './pages/SMODigital/SmoDigital';
import SmmDigital from './pages/SMMDigital/SmmDigital';
import ContentWriting from './pages/ContentWriting/ContentWriting';
import OdooDigital from './pages/OdooDigital/OdooDigital';
import ReactNative from './pages/ReactNative/ReactNative';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Blog from './Component/BlogSections/Blogs/Blog';
import Blog_sec_1 from './Component/BlogSections/Blog1/Blog_sec_1';
import Blog_sec_2 from './Component/BlogSections/Blog1/Blog_sec_2';
import BlogSec3 from './Component/BlogSections/Blog1/BlogSec3';
import BlogSec4 from './Component/BlogSections/Blog1/BlogSec4';
import BlogSec5 from './Component/BlogSections/Blog1/BlogSec5';

const metadata = {
  '/': { title: 'Asv Consulting Services | Expert Business Solutions for Sustainable Growth', description:  'Asv Consulting Services: Expert business consulting solutions tailored to help your company grow and succeed. Specializing in strategic planning, process optimization, and innovative problem-solving, we empower businesses to thrive in today’s competitive market. Partner with us for personalized, results-driven consulting that transforms your challenges into opportunities.', keywords: 'home, services, company' },
  '/career': { title: 'ACS - Career', description: 'Join our team', keywords: 'career, jobs' },
  '/about-us': { title: 'ACS - About Us | Expert Business Solutions and Strategic Consulting', description: 'Discover Asv Consulting Services, a trusted partner in delivering innovative business solutions. We specialize in management consulting, strategy development, and digital transformation to help organizations achieve sustainable growth and success. Learn more about our expertise, values, and commitment to excellence.', keywords: 'about, company' },
  '/contact-us': { title: 'Asv Consulting Services – Expert Business Solutions and Support', description: 'Get in touch with Asv Consulting Services for expert business solutions tailored to your needs. Contact our team today to discuss how we can help drive your business forward with strategic advice and customized consulting services.', keywords: 'contact, support' },
  '/services': { title: 'ACS - Services', description: 'Explore our services', keywords: 'services, offerings' },
  '/outsourcing': { title: 'ACS - Outsourcing', description: 'Outsourcing services offered', keywords: 'outsourcing' },
  '/industries': { title: 'ACS - Industries', description: 'Industries we serve', keywords: 'industries' },
  '/privacy-policy': { title: 'ACS - Privacy Policy', description: 'Our privacy policy', keywords: 'privacy' },
  '/terms-conditions': { title: 'ACS - Terms and Conditions', description: 'Terms of service', keywords: 'terms' },
  '/our-product': { title: 'ACS - Our Product', description: 'Our product offerings', keywords: 'product' },
  '/blog': { title: 'ACS - blog | Expert Business Solutions for Sustainable Growth', description: 'ACS Bloging', keywords: 'blog' },
};

function MetadataUpdater() {
  const location = useLocation();
  useEffect(() => {
    AOS.init({
      once:true
    });
  }, [])
  useEffect(() => {
    const updatePageMetadata = () => {
      const path = location.pathname;
      const pageMetadata = metadata[path] || metadata['/']; // Default to home if path not found

      // Update document title
      document.title = `${pageMetadata.title}`;

      // Update meta tags
      updateMetaTag('description', pageMetadata.description);
      updateMetaTag('keywords', pageMetadata.keywords);
    };

    const updateMetaTag = (name, content) => {
      let metaTag = document.querySelector(`meta[name="${name}"]`);
      if (metaTag) {
        metaTag.setAttribute("content", content);
      } else {
        metaTag = document.createElement("meta");
        metaTag.setAttribute("name", name);
        metaTag.setAttribute("content", content);
        document.head.appendChild(metaTag);
      }
    };

    updatePageMetadata();
  }, [location.pathname]);

  return null;
}

// const [show, setShow] = useState(false);

//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);

function App() {
  return (
    <>

{/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                autoFocus
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Example textarea</Form.Label>
              <Form.Control as="textarea" rows={3} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal> */}

    <Router>
      {/* MetadataUpdater should be inside Router to access useLocation */}
      <MetadataUpdater />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/career' element={<JobApplicationForm />} />
        <Route path='/about-us' element={<GlobalLearning />} />
        <Route path='/contact-us' element={<ContactUs />} />
        <Route path='/services' element={<Services />} />
        <Route path='/outsourcing' element={<BannerOutsource />} />
        <Route path='/industries' element={<Industries />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/terms-conditions' element={<TermsConditions />} />
        <Route path='/our-product' element={<OurProduct />} />
        <Route path='/blog' element={<Blog />} />
        <Route path='/blog-objective' element={<Blog_sec_1 />} />
        <Route path='/seo-noida-businesses' element={<Blog_sec_2 />} />
        <Route path='/blog-mobile-development' element={<BlogSec3 />} />
        <Route path='/blog-native-development' element={<BlogSec4 />} />
        <Route path='/blog-fluter-development' element={<BlogSec5 />} />

        {/* Services pages */}
        <Route path='/android-development' element={<AndroidDevelop />} />
        <Route path='/iphone-development' element={<IphoneDevelop />} />
        <Route path='/cross-platfrom' element={<CrossPlatfrom />} />
        <Route path='/react-native' element={<ReactNative />} />
        <Route path='/fluter-development' element={<FlutterDevelopment />} />
        <Route path='/php-development' element={<PhpDevelopment />} />
        <Route path='/react-development' element={<ReactDevelopment />} />
        <Route path='/node-development' element={<Nodedevelopment />} />
        <Route path='/wordpress' element={<WordpressDevelop />} />
        <Route path='/salesforce' element={<Salesforce />} />
        <Route path='/erp-framwork' element={<Erpframwork />} />
        <Route path='/odoo-digital' element={<OdooDigital />} />
        <Route path='/ci/cd-framwork' element={<CdFramwork />} />
        <Route path='/email-marketing' element={<EmailMarketing />} />
        <Route path='/ppc-services' element={<PpcManagement />} />
        <Route path='/brand-management' element={<BrandManagement />} />
        <Route path='/digital-marketing-services' element={<DigitalSeo />} />
        <Route path='/social-media-optimization' element={<SmoDigital />} />
        <Route path='/social-media-marketing' element={<SmmDigital />} />
        <Route path='/content-writing' element={<ContentWriting />} />
      </Routes>
    </Router>
    </>
  );
}

export default App;
