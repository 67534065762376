import React from 'react';
import { FaApple, FaAndroid, FaDesktop } from 'react-icons/fa'; // Importing icons from react-icons
import './AppDevelopmentSection.css'; // Import custom styles
import { Link } from 'react-router-dom';

const services = [
  {
    icon: <FaApple size={40} />,
    title: 'IOS App Development',
    delay:"400",
    description: 'Smart consultation, IoT, UX, AIs, Bots, and other technologies are synergized to give you the best solutions.',
  },
  {
    icon: <FaAndroid size={40} />,
    delay:"500",
    title: 'Android App Development',
    description: 'Native Android end-to-end app development for smart wearables, smartphones, and tablets with app upgrade & maintenance services.',
  },
  {
    icon: <FaDesktop size={40} />,
    delay:"600",
    title: 'Web App Development',
    description: 'User-friendly, flexible, and cost-effective web app development solutions compatible with a wide array of operating systems and devices.',
  },
];

const AppDevelopmentSection = () => {
  return (
    <div className="app-development-section">
      <h2 className="heading">Explore Our Dynamic Range of Development Solutions</h2>
      
      <div className="services-container">
        {services.map((service, index) => (
          <div className="service-card" key={index} data-aos="fade-up"
          data-aos-duration="3000"
          data-aos-delay={service.delay}>
            <div className="service-icon">{service.icon}</div>
            {/* Title is now just plain text */}
            <h3 className="service-title">{service.title}</h3>
            <p className="service-description">{service.description}</p>
            {/* Removed the Read More link */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AppDevelopmentSection;
